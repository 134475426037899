import capitalize from 'lodash/capitalize.js';
import { IDocument } from './getDocument.types';
import { documentTypesTranslations } from './getDocument.const';

export const formatDocumentName = (documentType: string): string => {
  const docName = documentType.split('.');
  return docName[docName.length - 1];
};

export const getDocumentNameID = ({ documentType, title }: IDocument) => {
  const docName = formatDocumentName(documentType as string);
  if (docName === 'other') {
    return title;
  } else {
    return documentTypesTranslations[docName] ?? '';
  }
};

export const getHumanReadableName = (document: IDocument): string => {
  const { documentType, title, subTitle } = document;
  return documentType?.includes('other')
    ? capitalize(`${title}${subTitle ? '-' + subTitle : ''}`)
    : capitalize(
      `${formatDocumentName(documentType as string)}${subTitle ? '-' + subTitle : ''}`,
    );
};
